@font-face{
    font-family: 'poppin';
    src : url('./fonts/Poppins-Bold.ttf');
    font-weight:bold;
    font-display:fallback;
}

@font-face{
    font-family: 'poppin';
    src : url('./fonts/Poppins-SemiBold.ttf');
    font-weight:700;
    font-display:fallback;
}

@font-face{
    font-family: 'poppin';
    src : url('./fonts/Poppins-Medium.ttf');
    font-weight:500;
    font-display:fallback;
}

@font-face{
    font-family: 'poppin';
    src : url('./fonts/Poppins-Regular.ttf');
    font-weight:400;
    font-display:fallback;
}

@font-face{
    font-family: 'poppin';
    src : url('./fonts/Poppins-Light.ttf');
    font-weight:100;
    font-display:fallback;
}

@font-face{
    font-family: pop_mid;
    src : url('./fonts/Poppins-Bold.ttf')
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Montserrat-Regular.ttf') ;
  }
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Montserrat-Medium.ttf') ;
  }
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/Montserrat-SemiBold.ttf') ;
  }

html, body {
    height:100vh;
  }

body{
    background: rgb(236, 240, 245);
    /* font-family: poppin; */
    
}
*{
    transition: all 0.3s ease-in-out;
    -moz-transition: -moz-all 0.3s ease-in-out;
    -o-transition: -o-all 0.3s ease-in-out;
}

svg{
    transition: all 0s ease-in-out;
    -moz-transition: -moz-all 0s ease-in-out;
    -o-transition: -o-all 0s ease-in-out;
}

.fnt-w4{
    font-weight: 400 !important;
}
.fnt-w5{
    font-weight: 500 !important;
}
.fnt-w8{
    font-weight: 800 !important;
}
.fnt-sm{
    font-size: small !important;
}
.fnt-smlr{
    font-size: smaller !important;
}
.fnt-med{
    font-size: medium !important;
}
.fnt-larg{
    font-size: large !important;
}
.fnt-largr{
    font-size: larger !important;
}
.txt_blk{
    color: #000000 !important;
}

.txt_wt{
    color: #fff !important;
}

.dvdr-r-gray{
    border-right: #0000003b 1px solid;
}
.dvdr-l-gray{
    border-left: #0000003b 1px solid;
}
.dvdr-t-gray{
    border-top: #0000003b 1px solid;
}
.dvdr-b-gray{
    border-bottom: #0000003b 1px solid;
}

.dvdr-r-fx-gray{
    border-right: #0000003b 1px solid;
}
.dvdr-l-fx-gray{
    border-left: #0000003b 1px solid;
}
.border-bl-rnd{
    border: #1463c5 1px solid;
    border-radius: 4px;
}

.full-width {
    width: 100% !important;
}

.txt-ctr{
    text-align: center !important;
}

.txt-rt{
    text-align: right !important;
}

.flex_ctr{
    display: flex;
    align-items: center;
    justify-content: center;
}
.round{
    border-radius: 50% !important;
}
.ht-inh{
    height: inherit !important;
}

.txt-blue{
    color: #1260bc;
}
.txt-blue-dark{
    color: #2a5ab4;
}
.txt-red{
    color: #e51b2f !important;
}

.txt-dark-bl{
    color: #0c046a !important;
}

.txt-ligth-bl{
    color: #4143e5 !important;
}

.txt-org{
    color: #FF5722 !important;
}
.txt-wt{
    color: #fff !important;
}
.txt-gray{
    color: #5a6c75 !important;
}
.txt-purple{
    color: #784ee1 !important;
}
.txt-purple2{
    color: #3f51b5 !important;
}
.green_box{
    border: #28a745 1px solid;
}

.bg-org{
    background-color: #FF5722 !important;
    background: #FF5722 !important;
}
.bg-grad-purple1{
    background: linear-gradient( 42deg
 , rgba(65,168,240,1) -20%, rgba(99,54,214,1) 100%);

}



#navbarCollapsemenu{
    background: transparent ;
}

.no-arrow::after {
    display:none !important;
}

.h-100{
    height: 100vh;
}
.scale15{
    transform: scale(1.5);
}

.btn_round_notif{
    background: #e4e6eb;
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

.btn_round {
    font-size: large;
    color: #ffffff;
    border: none;
    border-radius: 100%;
    box-shadow: 0 8px 6px -6px #00000057;
    width: 40px;
    height: 40px;
    background: #3f51b5;
    text-align: center;
    padding: 0px;
}

.btn_mini {
    width: 30px !important;
    height: 30px !important;
}

.btn_round:hover{
    background: #6279f9 ;
}
.btn_eff_purple {
    background-color: #5c4cdb !important;
    color: #fff !important;
}
.btn_eff_purple:hover {
    background-color: #335eea !important;
}
.skretch_box{
    min-height: inherit;
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    background: #fff;
}
.bill-title{
    background: #f2f2f2;
}

.box_item_btn_mini{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    color: #1260bc;
    border: #1260bc 1.25px solid;
    border-radius: 4px;
    padding: 6px 12px;
    box-shadow: 0 8px 6px -6px #00000057;
    background: #fff;
    text-decoration: none !important;
    height: fit-content;
}

.box_overlay_home{
    height: 720px;
    background: rgb(65,168,240);
    background: linear-gradient( 
42deg
 , rgba(65,168,240,1) 0%, rgba(99,54,214,1) 100%);
 background: linear-gradient( 
0deg , #82bbd3 0%, #6455db 100%);
 padding-top: 136px;
overflow-y: hidden;
font-family: 'Montserrat' , sans-serif !important;
}

.box_anim_img{
    z-index: 9;
    overflow-x: hidden;
}
.box_anim_img>img{
    margin-right: -56px;
}

.box-presentation{
    background-color: #fff;
    background-image: url('../img/bg_bridge.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding: 50px 0px 220px 0px !important;
    font-family: 'Montserrat' , sans-serif !important;
}

.btn-register-prest{
    background: #555fca;
    color: #fff;
    font-weight: 500;
    padding: 10px 26px;
    border-radius: 32px;
    border-color: #555fca;
}

.box-why{
    background-image: url('../img/bg-why.png');
    background-position: top center;
    background-size: cover;
    padding-top: 80px;
    margin-top: -200px !important;
    margin-left: 0px;
    margin-right: 0px;
}


.service-itm{
    padding: 60px 40px;
    border-radius: 5px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding:20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;

}


.box_service_1{
    /* border: #8a96cf 1.5px dashed; */
    border-top-left-radius: 32px;
    
}

#box_why{
    font-family: 'Montserrat' , sans-serif !important;
}
.box_service_2{
    /* border: #8a96cf 1.5px dashed; */
    border-top-right-radius: 32px;
    border-left: 0;
}

.box_service_3{
    /* border: #8a96cf 1.5px dashed; */
    border-bottom-left-radius: 32px;
    border-top: 0;
}

.box_service_4{
    /* border: #8a96cf 1.5px dashed; */
    border-bottom-right-radius: 32px;
    border-top: 0;
    border-left: 0;
}

.box_mision{
    box-shadow: inset 0 1px 9px 0 rgb(32 33 36 / 70%);
    font-family: 'Montserrat' , sans-serif !important;
}

.box_mision_txt{
    background: #fff;
    color: #2f2f35;
    box-shadow: 0 1px 9px 0 rgb(32 33 36 / 38%);
}
.box_mision_img{
    transform: scale(1.5);
    border: #e3eafd 2px dashed;
    border-radius: 100%;
    padding: 6px;
}

.img_mission{
    transform: scale(1.5);
    position: absolute;
    top: 34%;
}

.img_diag{
    width: 100%;
    height: 120px;
    padding: 0px;
    margin: 0px;
    fill: rgb(65, 67, 229);
}

@media (max-width: 576px) { 

    .slider-vdo{
        margin-top: 200px;
    }

    .rw-cont{
        margin-left: 50px !important;
        
    }
    .dvdr-r-gray{
        border-right: 0;
        border-bottom: #0000003b 1px solid;
    }
    .dvdr-l-gray{
        border-left: 0;
        border-bottom: #0000003b 1px solid;
    }

    .inf_top_box{
        display: none;
    }
    .banner-avatar{
        width: 50%;
    }
    .btn_bg_sign{
        width: 100% !important;
    }

    .inf_box_pop_r{
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
    }

    .logo_home{
        transform: scale(1) !important;
    }

    .box_anim_img> img{
        margin-right: -56px;
        width: 100% !important;
        margin-top: 24px;
    }

    .box_overlay_home{
        height: auto !important;
        font-family: 'Montserrat' , sans-serif !important ;
        
    }

    .img_mission{
        position: inherit !important;
    }

    .img_diag{
        height: 60px !important;
    }

    #header_nav .navbar-nav .nav-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    #header_nav .navbar-nav .nav-link {
        color: #3f51b5 ;
        border-radius: 0px !important;
    }

    #navbarCollapsemenu{
        background: #fff !important;
    }

    /* #header_nav .navbar-nav .nav-link {
        color: #3f51b5 ;
        border-radius: 0px;
    }
    #header_nav .navbar-nav .nav-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    
    
    #header_nav .navbar-nav .nav-link:hover {
        background: #ffffff;
        color: #3f51b5;
        
    }
    
    #header_nav .navbar-nav .nav-link>.active , .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
        background: #3f51b5 !important;
        color: #ffffff !important;
        border-radius: 0 !important;
    } */

 }

 /* --------------------------------------------end small screen */
.no-padd{
    padding: 0px !important;
}
.no-marg{
    margin: 0px !important;
}
.padd-l5{
    padding-left: 5px;
}
.padd-r5{
    padding-right: 5px;
}
.padd-l10{
    padding-left: 10px;
}
.padd-r10{
    padding-right: 10px;
}

.padd-5{
    padding: 5px;
}
.padd-10{
    padding: 10px;
}
.marg-5{
    margin: 5px;
}
.marg-10{
    margin: 10px;
}
.marg-t-10{
    margin-top: 10px !important;
}

.dsp_inl_flex{
    display: inline-flex;
}
.dsp_blk{
    display: block !important;
}
.bg-gray{
    background: #edf0f3 !important;
}
.bg-wt{
    background: #fff !important;
}
.bg-blue-gay{
    background: #ecf0f5;
}
.bg-wt2{
    background: #f9fbfd !important;
}
button:focus {
    outline: none;
    border: none;
}

.chek_size_lg>input{
    width: 16px !important;
    height: 16px !important;
}

.main-sidebar {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 230px;
    /* background: #004a81; */
    padding-top: 0px;
    box-shadow: 5px 0 1px rgb(0 0 0 / 13%);
    /* background: linear-gradient(
180deg
, #196ad6, #004a81); 
    background: linear-gradient( 
42deg
 , rgba(65,168,240,1) -20%, #6336d6 100%); */
    background: #3f51b5;
}
.sidebar {
    padding-bottom: 10px;
    height: auto;
}

.sidebar_logo , .sidebar_logo_adm{
    display: block;
    width: 100%;
    text-align: center;
    padding: 5px;
    /* background: #fff; */
    /* box-shadow: -10px 5px 10px rgb(0 0 0 / 25%);*/
}
.sidebar_logo>img{
    width: 100px;
    opacity: .9;
}
.sidebar_logo_adm>img{
    width: 80px;
}

.sidebar_logo_mini{
    padding: 0px;
    padding-top: 26px;
    padding-bottom: 26px;
}
.sidebar_logo_mini>img{
    width: 42px;
    opacity: 1;
}


.user-panel {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow: hidden;
    color: #fff;
}
.user-apnel-cont{
    background: #01010117;
    border-radius: 6px;
    border: #ffffff4a 1px solid;
    padding-top: 4px;
}
.user-panel-usr-icn{
    text-align: center;
    font-size: x-large;
    padding: 0px;
}
.user-panel-usr-data{
    line-height: 1rem;
    padding: 6px;
    background: #fff;
    color: #000;
    text-align: center;
    margin-top: 4px;
    border-radius: 6px;
    box-shadow: 0px -3px 5px rgb(0 0 0 / 17%);
    margin-bottom: -1px;
}
.user-panel-usr-data>p{
    font-size: small;
    margin: 2px;
    color: #535353;
}
.user-panel-usr-data>b{
    font-size: small;
}

.user_img{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 23%);
    border: #fff 1px solid;
    padding: 2px;
    object-fit: cover;
}

.user_img::after{
    border-radius: 100%;
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: 2;
    display: block;
    box-shadow: inset 0 0 0 2px white;
}
.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
}

.sidebar-menu_adm {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: calc(70vh) !important;
    line-height: 1.22 !important;
    padding-bottom: 32px;
}
.sidebar-menu>li.header {
    background: #1a2226;
    overflow: hidden;
    text-overflow: clip;
    padding: 10px 25px 10px 15px;
    font-size: 12px;
    color: #00bcd463;
    background: transparent;
    font-weight: bold;
}
.sidebar-menu>li {
    position: relative;
    margin: 0;
    padding: 0;
    transition: none;
}
.sidebar-menu>li:hover{
    background: #fff;
}

.sidebar-menu>li:hover a{
    color: #3f51b5 !important;
}

.sidebar-menu .activel{
    background: #fff !important;
    color: #3f51b5 !important;
}
.sidebar-menu>li>a {
    padding: 12px 5px 12px 15px;
    display: block;
    background: transparent;
    border-bottom: 1px #ffffff17 solid;
    border-left: 3px solid transparent;
    color: #fff;
    text-decoration: none;
    transition: none;
}

.cont_log_out{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-shadow: -10px -5px 10px rgba(0, 0, 0, 0.16);
}
.btn_log_out {
    border: none;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: 500;
    background: #00000078;
}

.content-wrapper {
    min-height: 100%;
    background-color: #ecf0f5;
    z-index: 800;
    min-height: 500px;
    margin-left: 230px;
}
.diagonal {
    position: absolute;
    left: 0;
    fill: #e66225;
    height: 24px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    -moz-transition: -moz-all 0.3s ease-in-out;
    -o-transition: -o-all 0.3s ease-in-out;
}

.hd-navbar {
    background-color: #fff;
    box-shadow: -2px 5px 5px rgb(0 0 0 / 21%);
    margin-bottom: 0;
    margin-left: 230px;
    border: none;
    min-height: 50px;
    max-height: 50px;
    border-radius: 0;
    border-top: #3f51b5 2px solid;
}
.hd-navbar-mini {
    margin-left: 50px;
}
.hd-navbar>.sidebar-toggle {
    border: none;
    color: #333;
    border-right: 1px solid #eee;
    cursor: pointer;
    background: #fff;
    padding: 12px 15px;
    min-height: 100%;
    display: inline-block;
    transition: none;
}

.hd-navbar>.sidebar-toggle:hover {
    background: #3f51b5;
    color:#fff;
}

.mini-sidebar{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px !important;
}

.hidebal{
    display:none;
}

.hide-scale{
    transform: scaleY(0);
    transform-origin: 0% 0%;
}
.show-scale{
    transform: scaleY(1);
    transform-origin: 0% 0%;
}

.card-menu{
    border: none;
    border-radius: 0;
    background: transparent;
    color: #fff;
}
.card-menu-header{
    padding: 0px;
    background: transparent;
}
.card-menu-btn{
    width: 100%;
    text-align: left;
    color: #fff;
    text-decoration: none !important;
    padding: 12px 5px 12px 18px;
    border-radius: 0px;
    border-bottom: 1px #ffffff17 solid;
}

.card-menu-btn:hover , .card-menu-btn:active , .card-menu-btn:focus{
    background: #fff;
    color: #1463c5;
}
.card-menu-container{
    background: rgba(0, 0, 0, 0.17);
}
.card-menu-container .card-body>ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.card-menu-container .card-body>ul>li>a{
    padding: 12px 5px 12px 26px;
    display: block;
    color: #fff;
    text-decoration: none;
}
.card-menu-container .card-body>ul>li>a:hover{
    text-decoration: none;
    background: #ffffff40;
}
.menu_icn_arrow{
    float: right;
    margin-right: 8px;
    margin-top: 8px;
}

/* ******************content ****************************************************************************************** */
.rw-cont{
    margin: 0px;
    margin-left: 230px;
    margin-top: 50px;
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    width: -moz-available;
    height: -moz-available;
    width: fill-available;
    height: fill-available;
    
}

.rw-cont-mini{
    margin-left: 50px;
}

.banner_box{
    position: relative;
    overflow: hidden;
    height: 120px;
    border-bottom: #00000052 1px solid;
}

.banner_box_img{
    width: 40%;
    position: absolute;
    right: 0px;
    top: -102%;
    z-index: 2;
}

.banner_box_effect{
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 120px;
    background: #00000038;
    background: linear-gradient( 
0deg
 , rgb(63 81 181) -20%, rgb(255 255 255) 100%);
    opacity: 0.3;
}

.banner_box_content{
    position: absolute;
    z-index: 4;
    bottom: 0;
    padding-left: 15px;
    color: #3f51b5;
}
.item_box{
    margin: 2px;
    margin-top: 5px;
    padding: 4px;
    background: #fff;
    /* box-shadow: 0 8px 6px -6px #00000057; */
    border-bottom: #0000004a 1px solid;
}
.item_box_active{
    background: #dee0e3 !important;
}
.item_box_ref{
    font-weight: 400;
}
.item_box_ref>span{
    font-weight: 500;
    color: #1260bc;
}
.item_box_money{
    color: #17a342;
}
.item_box_money>span{
    font-weight: 400;
    font-size: x-small;
}
.item_box_date{
    text-align: right;
    font-size: small;
}
.item_box_driver{
    font-weight: 500;
    color: #000;
}

.box_item_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    color: #1260bc;
    border: #1260bc 1.25px solid;
    border-radius: 4px;
    padding: 6px;
    margin-top:4px;
    box-shadow: 0 8px 6px -6px #00000057;
    width: 100%;
    background: #fff;
}
.box_item_btn:hover , .box_item_btn:focus , .box_item_btn_mini:hover , .box_item_btn_mini:focus {
    border: #1260bc 1.25px solid;
    background:#1260bc;
    color: #fff;
}

.tit_rw{
    background: #fff;
    margin-top: -4px;
}
.tit_icon_shape{
    display: inline-block;
    width: 100px;
    font-size: larger;
    padding: 7px;
    text-align: center;
    -webkit-clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    background: #1463c5;
    color: #ffffff;
}

.tit_text_shape{
    display: inline-block;
    font-size: large;
    font-weight: 500;
    padding-top: 12px;
    padding-left: 14px;
    background: #ffffff;
    color: #1464c5;
    -webkit-clip-path: polygon(13% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(13% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.tit_spacer_shape{
    display: inline-block;
    width: 50px;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
    margin-left: -8px;
    background: #1463c5;
    color: #03A9F4;
}

.rw-header{
    background: #fff;
    border-top: #1463c5 1px solid;
    box-shadow: 0 8px 6px -6px #00000057;
}

.btn_search{
    background: #1463c5;
    color: #fff;
    border-radius: 50%;
    padding: 2px 8px;
    margin-left: -37px;
    margin-top: -3px;
}

.btn_rnd{
    background: #1463c5;
    color: #fff;
    border-radius: 19px;
    padding: 6px 16px;
    margin-top: -6px;
}
.btn_search:hover , .btn_rnd:hover {
    color: #fff;
    background: #784ee1;
}

.inpt_search{
    display: inline-flex;
    width: 94%;
    border-radius: 16px;
    box-shadow: 0 8px 6px -6px #00000036;
    background: #edf0f3;
    font-size: small;
}
.inpt_search:hover{
    border: #1463c5 1px solid;
    background: #fff;
    padding-left: 32px;
}
.box_resum{
    padding: 8px;
    justify-content: center;
}
.box_resum-b{
    font-weight: 500;
}

.box_resum-b>span{
    color: #1463c5;
    font-weight: 700;
}
.inf_item{
    display: block;
    font-weight: 500;
}
.inf_item>span{
    color: #5f7681 ;
}

.inf_in_prog{
    color: #007bff;
}
.inf_in_prog_suc{
    color: #28a745;
}
.inf_in_prog_fail{
    color: #dc3545;
}

.rs_state_box{
    display: flex;
    width: fit-content;
    padding: 5px;
    color: #1463c5;
    border: #1463c5 1px solid;
    border-radius: 3px;
    font-size: small;
}
.inf_box_pop_r{
    position: fixed;
    top: 50px;
    right: 0;
    padding: 0px 0px 30px 0px;
    height: -webkit-fill-available;
    height: -moz-available; 
    height: fill-available;
    background: #fff;
    box-shadow: -5px 0 1px rgba(0,0,0,.13);
    overflow: auto;
    z-index: 99;
}
.inf_box_pop_r_off{
    width: 0px !important;
}
.inf_box_pop_r_off > *{
    display: none !important;
}
.btn_close{
    border: none;
    background: #dc3545;
    color: #fff;
    padding: 6px 11px;
}
.btn_close:hover , .btn_close:focus {
    background: #1463c5;
}




#tabs{
    color: #000;
}
#tabs h6.section-title{
    color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #1c1c1c;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    font-weight: bold;
    border-bottom: 2px solid !important;
    font-weight: 500;
    color: #1461c0 !important;
}
#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #1c1c1c;
    background: transparent;
}
#tabs .nav-tabs{
    box-shadow: -2px 3px 5px rgb(0 0 0 / 16%);
}
#tabs .tab-content{
    padding-top: 16px;
    padding-bottom: 16px;
}
.spn_inf_tit{
    color: #5f7681 !important;
    margin-right: 8px;
}


/* ----------------------------------------------Login css -----------------------------*/

.login-container{
    position: absolute;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    width: -moz-available; 
    height: -moz-available; 
    width: fill-available;
    height: fill-available;
    align-items: center;
    justify-content: center;
}

.login-container-form::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .login-container-form::-webkit-scrollbar-track {
    background: #0000007a; 
  }
   
  /* Handle */
  .login-container-form::-webkit-scrollbar-thumb {
    background: #3f51b5; 
  }
  
  /* Handle on hover */
  .login-container-form::-webkit-scrollbar-thumb:hover {
    background: #3f51b5; 
  }

  



  .blue_scrollb::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .blue_scrollb::-webkit-scrollbar-track {
    background: #0000007a; 
  }
   
  /* Handle */
  .blue_scrollb::-webkit-scrollbar-thumb {
    background: #3f51b5; 
  }
  
  /* Handle on hover */
  .blue_scrollb::-webkit-scrollbar-thumb:hover {
    background: #3f51b5; 
  }

.btn-login{
    border: none;
    background-color: #5c4cdb;
    width: 50%;
    font-weight: 400;
    border-radius: 32px;
    box-shadow: -1px 0px 5px rgb(0 0 0 / 8%);
    border: #5c4cdb 1.5px solid;
}

.btn_back_home{
    box-shadow: -1px 0px 5px rgb(0 0 0 / 8%);
    background: #fff;
    color: #5c4cdb;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: large;
    text-align: center;
    border: #5c4cdb54 1.5px solid;
    margin-top: 6px;
}

.btn_back_home:hover{
    background: #5c4cdb;
    color: #fff;
}

.btn-login:hover{
    color: #5c4cdb !important;
    background: #fff !important;
  
}

.btn_forget_mdp{
    display: block;
    text-decoration: none;
    font-weight: 500;
    color: #5c4cdb;
    text-align: right;
}

.k-stepper{
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: inherit;
    background: none;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.k-stepper .k-step-list{
    margin: 0;
    padding: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
    flex-direction: row;
    grid-row: 1;
    grid-column: 1 / -1;
}

.k-step-list .k-step{
    flex: 1 0 auto;
    text-align: center;
    max-width: calc(25%);
}

.k-step-list .k-step-link{
    margin: auto;
    max-width: 10em;
    -ms-flex-direction: column;
    flex-direction: column;
    outline: none;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.k-stepper .k-step-indic{
    border-color: #3f51b5;
    color: #ffffff;
    background-color: #3f51b5;
    border-radius: 50%;
    margin: 3px;
    width: 28px;
    height: 28px;
    border-width: 1px;
    border-style: solid;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: visible;
    transition-property: color, background-color, border-color;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    box-sizing: content-box;
}

.k-step-link .not-done{
    background-color: #8592dc !important;
    border-color: #8592dc !important;
}

.k-step-indic::after{
    border-radius: 100%;
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: 2;
    display: block;
    box-shadow: inset 0 0 0 2px white;
}

.k-stepper .k-icon{
    width: 1em;
    height: 1em;
    outline: 0;
    font-size: 16px;
    font-family: "WebComponentsIcons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.k-step-list .k-label{
    max-width: 10em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
}

.k-stepper-line{
    grid-column: 2/8;
    width: 100%;
    height: 2px;
    top: 17px;
    grid-row: 1 / -1;
    pointer-events: none;
    z-index: 0;
    background-color: #bfc1d0;
    grid-template-columns: 1fr;
    grid-template-rows: 100%;
    border-radius: 2px;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    line-height: 1;
    display: inline-grid;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
}

.force-apparence{
    -webkit-appearance: auto;
    appearance: auto;
}

.placeholder-icon{
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: #858ba7;
}

.btn_img_pick{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    font-size: x-large;
    background: #fff;
    color: #8592dc;
    border-color: #8592dc !important;
    border: #8592dc 2px dotted;
}

.btn_img_pick::after {
    border-radius: 100%;
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: 2;
    display: block;
    box-shadow: inset 0 0 0 2px white;
}

.btn_img_pick_src{
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 50%;
}

.btn_skill_item{
    background: #4c80e7;
    color: #fff;
    padding: 4px 12px;
    border-radius: 16px;
    border: none;
    margin: 3px;

}

.btn_skill_item span {
    padding-left: 4px;
    opacity: .8;
}

.btn_skill_item:hover{
    background: #3f51b5;
}

.skills_box{
    border: #0202023d 1px solid;
    border-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 99% ;
}

.frm_box{
    border: #0202023d 1px solid;
    border-radius: 4px;
    min-height: 200px;
    position: relative;
    background: #f5f5ff;
}

.frm_box_item{
    text-align: left;
    margin: 3px;
    border-radius: 3px;
    border: #0000003b 1px solid;
    padding: 2px;
}

.frm_box_item .ecole{
    color: #3f51b5;
}

.frm_box_item .date{
    font-weight: 500 !important;
    font-size: smaller;
    color: #5b5b5b;
}

.frm_box_txt_emty{
    width: inherit;
    height: fit-content;
    position: absolute;
    top: 50%;
    display: block;
    text-align: center;
    color: #b5b4bc;
    font-weight: 500;
}

.skill_inpt{
    display: inline-block;
    width: 93.4%;
    border-radius: 0px;
    border-top: 0;
}

.avatar_prog {
    position: absolute;
    bottom: 0;
    width: 80%;
    height: auto;
    opacity: 0.9;
    margin: auto;
}

.avatar_logo{
    position: absolute;
    top: 0;
    width: 80%;
    height: auto;
    opacity: 1;
    left: 10%;
}

@keyframes shake{
    0% { transform:translate(0,0) }
   1.78571% { transform:translate(5px,0) }
   3.57143% { transform:translate(0,0) }
   5.35714% { transform:translate(5px,0) }
   7.14286% { transform:translate(0,0) }
   8.92857% { transform:translate(5px,0) }
   10.71429% { transform:translate(0,0) }
   100% { transform:translate(0,0) }
 }

 .barcode_icon{
    animation: shake 1s infinite;
    -webkit-animation: shake 3s infinite;
 }

 /*---------------------------------------------------------home------------------------------------*/
 .mini-box{
    box-shadow: 0px 2px 6px 0px #00000036;
    margin: 0;
    margin-top: 8px;
 }
.mini_box_header{
    background: #0c59aa;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.mini_box_header_number{
    font-size: x-large;
}
.mini_box_rs{
    margin: 0;
    box-shadow: none;
    border-bottom: #92a0a7 1px solid;
}
.mini_box_rs .sp-date{
    margin: 0;
    font-size: small;
    color: #5a6c75;
}
.sm-gray-txt{
    color: #5a6c75 !important;
    font-size: small !important;
}

.box_data_rnd{
    border-radius: 32px;
    margin-bottom: 6px;
    background: #f8f9fb;
    border: #0000002e 1px solid;
}

.box_data_rnd .icon{
    background: #0c59aa;
    padding: 8px;
    border-radius: 50%;
    margin: 3px;
    color: #fff;
    height: 46px;
    width: 46px;
    font-size: x-large;
    text-align: center;
}
.item_box .icon{
    background: #0c59aa;
    padding: 6px;
    border-radius: 50%;
    margin: 3px;
    color: #fff;
    height: 38px;
    width: 38px;
    font-size: larger;
    text-align: center;
}

.mini_title{
    background: #fff;
    color: #e51b2f;
    padding: 6px;
}
.mini_title_r{
    background: #1260bc;
    color: #fff;
    padding: 6px;
    padding-right: 64px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}

.small-label{
    font-size: small;
    font-weight: 500;
}
/*-------------------------------------------------------------Home page----------- */

.bg-overlay{
    position: absolute;
    bottom: 0;
    /* width: 100%;
    height: -webkit-fill-available;
    background: linear-gradient(50.89deg, #184a84 17%, #dc4a40 100%);
    opacity: 0.75; */
}
.nav_ctr{
    margin-left: auto;
    margin-right: auto;
}
.flip-x{
    transform: scaleX(-1) !important;
}

.logo_home{
    transform: scale(1.5) !important;
}

.home_anim_container{
    z-index: 99999;
    position: absolute;
    bottom: -64px;
    right: 20%;
    width: 40%;
}

.txt_anim_h1{
    color: #fff !important;
    text-shadow: 2px 1px 0 #3c3c3c42;
}

.txt_anim_h2{
    color: #0c046a !important;
    text-shadow: 2px 1px 0 #8b8cf3;
}

.btn_start{
    width: 40%;
    margin-top: 24px;
    border: #4143e5 1px solid !important;
    color: #4143e5 !important;
    
}

.btn_slider{
    width: 40%;
    margin-top: 24px;
    border: #0c046a52 1px solid !important;
    color: #323438 !important;
    padding: 12px;
    /* width: 30%; */
    border-radius: 6px;
    background: #ffc800;
    border: #6336d6 1px solid;
    color: #0c046a;
    font-weight: 500;
    font-size: large;
    box-shadow: 0px 7px 15px -6px #0000007d;
}

.btn_start:hover , .btn_start:focus , .btn_slider:hover , .btn_slider:focus{
    color: #fff !important;
}

.item_srvice_img{
    width: 80px;
    height: 80px;
    padding: 8px;
    /* border-radius: 50%; */
    /* background: #fff; */
    margin-bottom: 12px;
    /* box-shadow: 1px 1px 20px 1px #0000001a; */
}

#header_nav{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: #fff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    font-family: 'Montserrat' , sans-serif !important;
}

#header_nav .navbar-nav .nav-link {
    color: #3f51b5;
    font-size: 1.12rem;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
    font-weight: 500;
    border-radius: 24px;
    font-family: 'Montserrat' , sans-serif !important;
}
#header_nav .navbar-nav .nav-item {
    margin-left: 3px;
    margin-right: 3px;
}


#header_nav .navbar-nav .nav-link:hover {
    background: #3f51b5;
    color: #fff !important;
    
}

#header_nav .navbar-nav .nav-link>.active , .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    background: #3f51b5;
    color: #ffffff !important;
    border-radius: 24px;
}
#header_nav .navbar-nav .nav-link .link_btm_arrow {
    visibility: hidden;
}
#header_nav .navbar-nav .active .link_btm_arrow {
    visibility: visible;
}
.link_btm_arrow{
    position: absolute;
    bottom: -10px;
    left: 45%;
}

.inf_top_box{
    position: absolute;
    top: 0;
    right: 15px;
    font-size: small;
    color: #e51b2f;
    z-index: 1032;
    border: #e51b2f 1px solid;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.21);
}

.side_sm_box{
    position: fixed;
    top: 45%;
    right: 0;
    width: fit-content;
    color: #fff;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    -webkit-box-shadow: -8px 0px 14px -4px rgba(0,0,0,0.45);
    -moz-box-shadow: -8px 0px 14px -4px rgba(0,0,0,0.45);
    box-shadow: -8px 0px 14px -4px rgba(0,0,0,0.45);
    font-size: x-large;
    z-index: 10;
}
.side_fb_item{
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    background: #004a81;
    border: #fff 1px solid;
    border-top-left-radius: 16px;
    color: #fff ;
    
}
.side_insta_item{
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    background: #e51b2f;
    border: #fff 1px solid;
    border-bottom-left-radius: 16px;
    color: #fff ;
}

.side_fb_item>a , .side_insta_item>a{
    color: #fff !important;
    transition: all 0s ease-in-out !important;
    -moz-transition: -moz-all 0s ease-in-out;
    -o-transition: -o-all 0s ease-in-out;
}
.side_fb_item:hover{
    color: #004a81;
    background: #fff;
}

.side_fb_item:hover a{
    color: #004a81 !important;
}
.side_insta_item:hover{
    color: #e51b2f;
    background: #fff;
}
.side_insta_item:hover a{
    color: #e51b2f !important;
}

.btn_bg_sign{
    padding: 12px;
    width: 30%;
    border-radius: 32px;
    background: #fff;
    border: #6336d6 1px solid;
    color: #6336d6;
    font-weight: 500;
    font-size: large;
    box-shadow: 0px 7px 15px -6px #0000007d;
}


.service_box_info{
    font-size: larger;
    display: flex;
    align-items: center;
}

.footer {
    background: #2f2f35;
    color: #d7d7e0;
    padding-top: 24px;
    font-family: 'Montserrat' , sans-serif !important;
}
.footer_logo{
    filter: grayscale(25%);
    -webkit-filter: grayscale(25%);
    -moz-filter: grayscale(25%);
    -ms-filter: grayscale(25%);
    -o-filter: grayscale(25%);
}

.footer-title{
    color: #d7d7e0;
    display: inline-block;
    margin-bottom: 10px;
    font-size: x-large;
    font-weight: 100;
}

.contact_icon{
    color: #2f2f35;
    background: #ffc800;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100%;
    padding-top: 3px;
}

.footer_links{
    display: block;
    color: #fff;
    text-decoration: none;
}
.footer_links:hover{
    color: #4143e5;
    padding-left: 8px;
    text-decoration: none;
}

.footer_social{
    display: inline-block;
    background: #fff;
    width: 42px;
    height: 42px;
    font-size: larger;
    text-align: center;
    padding-top: 6px;
    border-radius: 6px;
    color: #2f2f35;
}

.home-row-ctn{
    margin-top: 112px;
    margin-left: 0;
    margin-right: 0;
  
}

.btn_register{
    background: #fff;
    color: #e51b2f;
    border-color: #e51b2f;
    border-radius: 16px;
    padding: 8px 32px;
    box-shadow: 0 8px 6px -6px #0000003b;
}

.loading-row{
    margin-top: 112px;
    margin-left: 0;
    margin-right: 0;
    min-height: 460px;
    display: flex;
    align-items: center;
}

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      /*page-break-before: auto;*/
      page-break-before: unset;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }

.css-1uccc91-singleValue{
    display: none !important;
}
.react-select__option{
    color:red !important
}


/* width */
.sidebar-menu::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .sidebar-menu::-webkit-scrollbar-track {
    background: #0000007a; 
  }
   
  /* Handle */
  .sidebar-menu::-webkit-scrollbar-thumb {
    background: #fff; 
  }
  
  /* Handle on hover */
  .sidebar-menu::-webkit-scrollbar-thumb:hover {
    background: #fff; 
  }

  .about_box{
    padding: 32px;
    background: #004a81;
    color: #fff;
    text-align: center;
  }

  .andro_title{
    background: #0a2f42;
    text-align: center;
    color: #3ddb86;

  }

  .zone-box{
    color: #fff;
    background: linear-gradient(-90deg, #ee2320, #e51b2f);
  }

  .badge-out-succ{
    color: #28a745;
    border: 1px solid #28a745;
    font-size: small;
    padding: 2px;
    border-radius: 3px;
  }

  .cond_item{
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    -webkit-font-smoothing: antialiased;
    border-radius: 8px;
    border: #d0d0e9 1px solid;
  }

  .cond_item_img{
    box-shadow: none !important;
    border: #3f51b5ed 2px solid !important;
    width :100px;
    height: 100px;
  }

  .cond_item_t2{
    color: #212b60 !important;
  }

  .cond_item_txt_good{
    color: #13b08c;
    font-size: x-large;
  }

  .cond_item_txt_med{
    color: #ffb307;
    font-size: x-large;
  }

  .cond_item_txt_bad{
    color: #f44336;
    font-size: x-large;
  }

  .cond_item_prog_bad{
    background: #f44336;
    height: 20px;
  }
  .cond_item_prog_med{
    background: #ffb307;
    height: 20px;
  }
  .cond_item_prog_good{
    background: #13b08c;
    height: 20px;
  }

  .stat_box_title{
    display: block;
    padding: 6px;
    background: #4a45ab;
    color: #ffffff;
    margin-left: 0px;
    clip-path: polygon(0 0, 55% 0, 60% 100%, 0% 100%);
    border-bottom: #00bcd485 2px solid;
    font-weight: 500;
    margin-top: 6px;
    width: fit-content;
    padding-right: 40%;
}

.chat_users_box{
    height: 100% !important;
    padding: 0px;
}

.chat_user_item_img{
    width: 50px;
    height: 50px;
    box-shadow: none;
    border: #fff 1px solid;
    border-color: #3f51b561;
    
}

.chat_user_item{
   cursor: pointer;
   border-radius: 8px;
   background: #fff;
   position: relative;
    
}
.chat_user_item:hover{
    background: #e9eaee;   
}

.chat_user_item_sel{
    background: #e9eaee;   
}

.chat_users_box_header{
    height: 15%;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 21%);
}

.chat_users_box_content{
    height: 80%;
    overflow-y: auto;
}



  .chat_dissc_box{
    height: 100% !important;
    border-left: #00000029 1px solid;
    border-right: #00000029 1px solid;
  }

  .chat_dissc_user{
    box-shadow: 0px 0px 4px rgb(0 0 0 / 21%);
    padding: 4px;
  }

  .chat_dissc_header{
    height: 10%;
  }

  .chat_dissc_body{
    height: 80%;
    overflow-y: auto;
  }

  .bull_msg_user_co{
    padding: 8px 15px;
    background: #e4e6eb;
    border-radius: 32px;
    border-top-left-radius: 0px;
    width: fit-content;
  }
  .bull_msg_user_me{
    padding: 8px 15px;
    background: #3f51b5;
    color: #fff;
    border-radius: 32px;
    border-top-right-radius: 0px;
    width: fit-content;
    text-align: right;
  }

  .sp_connected{
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #13b08c;
    border-radius: 100%;
    margin-left: -10px;
    margin-bottom: -12px;
    border: #fff 2px solid;
  }

  .sp_deco{
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #c1c1c1;
    border-radius: 100%;
    margin-left: -10px;
    margin-bottom: -12px;
    border: #fff 2px solid;
  }

  .badge_msg{
    position: absolute;
    right: 6px;
    top: 6px !important;
    font-size: x-small;
    font-weight: 500;
  }
  

  .chat_users_box > *::-webkit-scrollbar , .chat_dissc_body::-webkit-scrollbar{
    width: 6px;
  }
  
  /* Track */
  .chat_users_box > *::-webkit-scrollbar-track , .chat_dissc_body::-webkit-scrollbar-track{
    background: #0000003d; 
  }
   
  /* Handle */
  .chat_users_box > *::-webkit-scrollbar-thumb , .chat_dissc_body::-webkit-scrollbar-thumb{
    background: #3f51b5ab; 
    border-radius: 32px;
  }
  
  /* Handle on hover */
  .chat_users_box > *::-webkit-scrollbar-thumb:hover , .chat_dissc_body::-webkit-scrollbar-thumb:hover {
    background: #3f51b5; 
  }



  .path {
    stroke-dasharray: 50;
    animation: dash 5s linear infinite ;
    stroke-width: 4 !important;
    
  }
  
  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }

  .home_info_box{
    box-shadow: 0rem 0rem 1rem rgba(22,28,45,.2)!important;
    border-radius: 4px;
    background: #fff;
  }

  .home_info_box_ctn_icon{
    background: #3f3c8e;
    color: #fff;
    position: relative;
    border-radius: 4px;
  }

  .home_info_box_icon{
    font-size: xxx-large;
    display: block;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 0.8;
  }

  .home_info_box_shape_ctn{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }

  .home_info_box_shape{
    height: 100%;
    width: auto;
    bottom: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    transform-origin: center left;
    transform: scale(2);
  }

  .home_info_box_txt_value{
    color: #3f3c8e;
  }

  .btn_banner_home{
    position: absolute;
    right: 10px;
    bottom: 16px;
    border: none;
    background-color: #5c4cdb;
    width: 40%;
    font-weight: 400;
    border-radius: 4px;
    box-shadow: -1px 0px 5px rgb(0 0 0 / 8%);
    border: #5c4cdb 1.5px solid;
  }


.elem-anim-si{
    animation-duration: 2s;
    animation-name: slidein-e;
}
.elem-anim-si2{
    animation-duration: 2s;
    animation-name: slidein-s;
}
.elem-anim-si3{
    animation-duration: 1s;
    animation-name: slidein-s;
}


  @keyframes slidein-e {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 0%;
    
    }
  }


  @keyframes slidein-s {
    from {
      margin-left: -100%;
    }
  
    to {
      margin-left: 0%;
    
    }
  }


.btn-menu-register{
    clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0 100%);
    background: #ffc800;
    color: #263238 !important;
    padding-left: 39px !important;
    border-radius: 4px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.btn-menu-login{
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0 100%);
    background: #ffc800;
    color: #263238 !important;
    padding-right: 39px !important;
    border-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.box-contact{
    background-image: url('../img/bg_contact.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Montserrat' , sans-serif !important;
}

.slider-vdo{
    margin-top: -240px;
}